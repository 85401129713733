const React = require("react"); // eslint-disable-line no-unused-vars

const addNoIndexMeta = () => {
  const meta = document.createElement("meta");
  meta.name = "robots";
  meta.content = "noindex";
  const head = document.getElementsByTagName("head")[0];
  head.appendChild(meta);
};

const configureGTMInHead = (w, d, l, i) => {
  w[l] = w[l] || [];
  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  const existingScript = d.getElementsByTagName("head")[0],
    script = d.createElement("script"),
    dl = l !== "dataLayer" ? "&l=" + l : "";
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
  existingScript.appendChild(script);
};

const configureHotjar = (h, o, t, j, a, r) => {
  h.hj =
    h.hj ||
    function () {
      (h.hj.q = h.hj.q || []).push(arguments);
    };
  h._hjSettings = { hjid: 2469286, hjsv: 6 };
  a = o.getElementsByTagName("head")[0];
  r = o.createElement("script");
  r.async = 1;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
};

const configureGTMInBody = () => {
  const noScript = document.createElement("noscript");
  const i = document.createElement("iframe");
  i.src = "https://www.googletagmanager.com/ns.html?id=GTM-NBRP29D";
  i.height = "0";
  i.width = "0";
  i.title = "gtm";
  const rules = i.style;
  rules.display = "none";
  rules.visibility = "hidden";
  i.style = rules;
  noScript.appendChild(i);
  const dBody = document.getElementsByTagName("body")[0];
  dBody.appendChild(noScript);
};

const configureGumlet = () => {
  const script = document.createElement("script");
  script.src = "https://cdn.gumlet.com/gumlet.js/2.1/gumlet.min.js";
  script.async = 1;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const configureGoogleOptimize = () => {
  const script = document.createElement('script');
  script.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-TTB4GL6';
  script.async = 1;
  document.getElementsByTagName('head')[0].appendChild(script);
};

const configureZoho = () => {
  window.$zoho = window.$zoho || {};
  window.$zoho.salesiq = window.$zoho.salesiq || {
    widgetcode:
      '5a3e21632ad6679cd9fc6dfbe7ea25e595a831c1b21dd314fe9d0aba58f750d0075ebc810d87ea2dc038407d6030b289',
    values: {},
    ready: function () {
      // This is intentional
    }
  };
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.id = 'zsiqscript';
  s.defer = true;
  s.src = 'https://salesiq.zoho.in/widget';
  const t = document.getElementsByTagName('script')[0];
  t.parentNode.insertBefore(s, t);
};

exports.onClientEntry = () => {
  window.onload = () => {
    window.GUMLET_CONFIG = {
      hosts: [
        {
          current: "assets-sv-in-lander.s3.ap-south-1.amazonaws.com",
          gumlet: "assets-sv-in.gumlet.io",
        },
      ],
      lazy_load: true, //If you need to disable lazy load for any image, just add data-gmlazy="false" to the <img>. Ref: https://www.gumlet.com/docs/image/integration-guides/frontend-javascript#usage
    };
    configureGumlet();
    setTimeout(() => {
      // Only track on production page.
      if (
        window.location.hostname.includes("springverify.com") &&
        !window.location.href.includes("dev.in.springverify") &&
        !window.location.href.includes("acceptance.in.springverify")
      ) {
        configureGTMInHead(window, document, "dataLayer", "GTM-NBRP29D");
        configureGTMInBody();
        if (window.location.pathname === "/background-verification/") {
          configureHotjar(
            window,
            document,
            "https://static.hotjar.com/c/hotjar-",
            ".js?sv="
          );
          configureGoogleOptimize();
        }
        configureZoho();
      }
      if (
        window.location.href.includes("dev.in.springverify") ||
        window.location.href.includes("acceptance.in.springverify")
      )
        addNoIndexMeta();
    }, 2000);
  };
};
